import axios from 'axios';
import { showMessage } from 'react/app/store/actions/fuse';

export const GET_RTPMULTICASTGROUP = '[MANAGE-COMMAND APP] GET RTPMULTICASTGROUP';
export const SET_RTPMULTICASTGROUPS_SEARCH_TEXT = '[MANAGE-COMMAND APP] SET RTPMULTICASTGROUPS SEARCH TEXT';
export const NEW_RTPMULTICASTGROUP = '[MANAGE-COMMAND APP] NEW RTPMULTICASTGROUP';

export const GET_RTPMULTICASTGROUPS = '[MANAGE-COMMAND APP] GET RTPMULTICASTGROUPS';
export const SAVE_RTPMULTICASTGROUP = '[MANAGE-COMMAND APP] SAVE RTPMULTICASTGROUP';
export const DELETE_RTPMULTICASTGROUP = '[MANAGE-COMMAND APP] DELETE RTPMULTICASTGROUP';
export const CLEAR_RTPMULTICASTGROUPS_STORE = '[MANAGE-COMMAND APP] CLEAR RTPMULTICASTGROUPS STORE';
export const RTPMULTICASTGROUPS_DIALOG_DATA = '[MANAGE-COMMAND APP] RTPMULTICASTGROUPS DIALOG DATA';

export function clearRTPMulticastGroupsStore() {
	return dispatch =>
		dispatch({
			type: CLEAR_RTPMULTICASTGROUPS_STORE
		});
}

export function rtpMulticastGroupDialogData(state) {
	return {
		type: RTPMULTICASTGROUPS_DIALOG_DATA,
		payload: state
	};
}

export function newRTPMulticastGroup(data, message) {
	const request = axios.post('EpttaAdmin/RTPMulticastGroups', data);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message }));

			return dispatch({
				type: NEW_RTPMULTICASTGROUP,
				payload: response.data
			});
		});
}

export function getRTPMulticastGroups() {
	const request = axios.get(`EpttaAdmin/RTPMulticastGroups`);

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_RTPMULTICASTGROUPS,
				payload: response.data
			})
		);
}

export function setRTPMulticastGroupsSearchText(event) {
	return {
		type: SET_RTPMULTICASTGROUPS_SEARCH_TEXT,
		searchText: event.target.value
	};
}

export function getRTPMulticastGroup(groupId) {
	const request = axios.get(`EpttaAdmin/RTPMulticastGroups/${groupId}`);

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_RTPMULTICASTGROUP,
				payload: response.data
			})
		);
}

export function saveRTPMulticastGroup(data, message) {
	const request = axios.put('EpttaAdmin/RTPMulticastGroups', data);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message }));

			return dispatch({
				type: SAVE_RTPMULTICASTGROUP,
				payload: data
			});
		});
}

export function deleteRTPMulticastGroup(data, message) {
	const request = axios.delete(`EpttaAdmin/RTPMulticastGroups?id=${data._id}`);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message }));

			return dispatch({
				type: DELETE_RTPMULTICASTGROUP,
				payload: data
			});
		});
}

export function newRTPMulticastGroupsTemplate(tenant) {
	const data = {
		_id: 'new',
		tenantId: tenant,
		name: '',
		channelId: '',
		multicastIP: '',
		multicastPort: '',
		created: '2024-03-30T12:13:24.854Z',
		lastUpdate: '2024-03-30T12:13:24.854Z'
	};

	return {
		type: GET_RTPMULTICASTGROUP,
		payload: data
	};
}

import * as _ from 'lodash';
import * as Actions from '../actions';

const initialState = {
	rtpMulticastGroupsData: null,
	searchText: '',
	rtpMulticastGroupData: null,
	delRTPMulticastGroup: null,
	rtpMulticastGroupDialogData: {
		state: false,
		id: 'new'
	}
};

const rtpMulticastGroupsReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_RTPMULTICASTGROUPS: {
			if (action.payload.length > 0) {
				const sortetData = _.sortBy(action.payload, 'name');
				return {
					...state,
					rtpMulticastGroupsData: sortetData
				};
			}

			return {
				...state,
				rtpMulticastGroupsData: []
			};
		}
		case Actions.SET_RTPMULTICASTGROUPS_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText
			};
		}
		case Actions.NEW_RTPMULTICASTGROUP: {
			return {
				...state,
				rtpMulticastGroupsData: state.rtpMulticastGroupsData.concat(action.payload)
			};
		}
		case Actions.GET_RTPMULTICASTGROUP: {
			return {
				...state,
				rtpMulticastGroupData: action.payload
			};
		}
		case Actions.SAVE_RTPMULTICASTGROUP: {
			const data = state.rtpMulticastGroupsData.filter(item => item._id !== action.payload._id);
			const data2 = data.concat(action.payload);
			return {
				...state,
				rtpMulticastGroupData: null,
				rtpMulticastGroupsData: data2
			};
		}
		case Actions.RTPMULTICASTGROUPS_DIALOG_DATA: {
			return {
				...state,
				rtpMulticastGroupDialogData: {
					state: action.payload.state,
					id: action.payload.id
				}
			};
		}
		case Actions.DELETE_RTPMULTICASTGROUP: {
			return {
				...state,
				delMapProvider: action,
				rtpMulticastGroupData: null,
				rtpMulticastGroupsData: state.rtpMulticastGroupsData.filter(data => data._id !== action.payload._id)
			};
		}
		case Actions.CLEAR_RTPMULTICASTGROUPS_STORE: {
			return {
				...initialState
			};
		}
		default: {
			return state;
		}
	}
};

export default rtpMulticastGroupsReducer;

import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import ar from '../i18n/ar';
import en from '../i18n/en';
import tr from '../i18n/tr';
import gr from '../i18n/gr'; //Akisx

i18next.addResourceBundle('en', 'manageAgents', en);
i18next.addResourceBundle('tr', 'manageAgents', tr);
i18next.addResourceBundle('ar', 'manageAgents', ar);
i18next.addResourceBundle('gr', 'manageAgents', gr); //Akisx

const TenantAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: '/apps/tenant/tenantSettings/:tenantSettingsId/:tenantSettingsName?',
			component: React.lazy(() => import('./tenantSettings/TenantSettings'))
		},
		{
			path: '/apps/tenant/tenantSettings',
			component: React.lazy(() => import('./tenantSettings/TenantSettings'))
		},
		{
			path: '/apps/super-admin/tenantSettings',
			component: React.lazy(() => import('./tenantSettings/TenantSettings'))
		},
		{
			path: '/apps/tenant/mapProviders/:mapProvidersId/:mapProvidersName?',
			component: React.lazy(() => import('./mapProviders/MapProviders'))
		},
		{
			path: '/apps/tenant/mapProviders',
			component: React.lazy(() => import('./mapProviders/MapProviders'))
		},
		{
			path: '/apps/tenant/rtpMulticastGroups',
			component: React.lazy(() => import('./rtpMulticastGroups/RTPMulticastGroups'))
		},
		{
			path: '/apps/tenant/admins/:adminsId/:adminsName?',
			component: React.lazy(() => import('./admins/Admins'))
		},
		{
			path: '/apps/tenant/admins',
			component: React.lazy(() => import('./admins/Admins'))
		},
		{
			path: '/apps/tenant',
			component: () => <Redirect to="/apps/tenant/tenantSettings" />
		}
	]
};

export default TenantAppConfig;

import axios from 'axios';
import { showMessage } from 'react/app/store/actions/fuse';

export const GET_CHANNELSSUBS = '[MANAGE-COMMAND APP] GET CHANNELSSUBS'; //akisx
export const SET_SELECTED_CSOURCE_AGENT = '[MANAGE-COMMAND APP] SET SET_SELECTED_CSOURCE_AGENT';
export const SET_SELECTED_CTARGET_CHANNEL = '[MANAGE-COMMAND APP] SET SET_SELECTED_CTARGET_CHANNEL';
export const SAVE_CHANNELSSUBS = '[MANAGE-COMMAND APP] SAVE CHANNELSSUBS'; //akisx
export const NEW_CHANNELSUB = '[MANAGE-COMMAND APP] NEW CHANNELSUB'; //akisx
export const DELETE_CHANNELSUB = '[MANAGE-COMMAND APP] DELETE CHANNELSUB'; //akisx
export const GET_CHANNELSUB = '[MANAGE-COMMAND APP] GET CHANNELSUB'; //akisx
export const CLEAR_CHANNEL_SUBS_STORE = '[MANAGE-COMMAND APP] CLEAR CHANNEL SUBS STORE'; //akisx
export const SAVE_MULTI_CHANNEL_SUBS = '[MANAGE-COMMAND APP] SAVE MULTI CHANNEL SUBS'; //akisx
export const IS_MULTI_CHANNELS_SUBS = '[MANAGE-COMMAND APP] IS MULTI CHANNELS SUBS'; //akisx

export function clearChannelSubsStore() {
	return dispatch =>
		dispatch({
			type: CLEAR_CHANNEL_SUBS_STORE
		});
}

export function newChannelSub(data, message) {
	const request = axios.post('EpttaAdmin/ChannelSubscription', data);

	return dispatch =>
		request
			.then(response => {
				dispatch(showMessage({ variant: 'success', message: message }));
				//dispatch(showMessage({ variant: 'success', message: 'Subscription Saved' }));

				return dispatch({
					type: NEW_CHANNELSUB,
					payload: response.data
				});
			})
			.catch(e => {
				if (e?.response && e.response?.data) {
					if (e.response.data.error) {
						dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
					} else {
						dispatch(showMessage({ variant: 'error', message: e.response.data }));
					}

					//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
				} else {
					dispatch(showMessage({ message: 'Unhandle ERROR' }));
				}
			});
}

export function deleteChannelSub(targetDeleteChannelSub, message) {
	const request = axios.delete(`EpttaAdmin/ChannelSubscription?id=${targetDeleteChannelSub._id}`);

	return dispatch =>
		request
			.then(response => {
				dispatch(showMessage({ variant: 'success', message: message }));
				//dispatch(showMessage({ variant: 'success', message: 'Subscription Deleted' }));
				//console.log("delete response: " +JSON.stringify(targetDeleteChannelSub));

				return dispatch({
					type: DELETE_CHANNELSUB,
					payload: targetDeleteChannelSub
				});
			})
			.catch(e => {
				if (e?.response && e.response?.data) {
					if (e.response.data.error) {
						dispatch(showMessage({ message: e.response.data.error }));
					} else {
						dispatch(showMessage({ message: e.response.data }));
					}

					//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
				} else {
					dispatch(showMessage({ message: 'Unhandle ERROR' }));
				}
			});
}

export function getChannelsSubs() {
	const request = axios.get(`EpttaAdmin/ChannelSubscriptions/GetByType?page=0&size=999999&type=C`);

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_CHANNELSSUBS,
				payload: response.data.content
			})
		);
}

export function saveChannelsSubs(data, message, setClearForm) {
	const request = axios.put('EpttaAdmin/ChannelSubscription', data);
	return dispatch =>
		request
			.then(response => {
				dispatch(showMessage({ variant: 'success', message: message }));
				//dispatch(showMessage({variant: 'success', message: 'Subscriptions Saved' }));

				//console.log("save response: " +JSON.stringify(response));
				setClearForm(data);
				return dispatch({
					type: SAVE_CHANNELSSUBS,
					payload: response.data
				});
			})
			.catch(e => {
				if (e?.response && e.response?.data) {
					if (e.response.data.error) {
						dispatch(showMessage({ message: e.response.data.error }));
					} else {
						dispatch(showMessage({ message: e.response.data }));
					}

					//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
				} else {
					dispatch(showMessage({ message: 'Unhandle ERROR' }));
				}
			});
}
export function saveMultiChannelSubs(channelIdsList, data, message) {
	data._id = 'multi';
	data.channelId = '';
	let multiChannelSubsObj = {
		channelIdsList: channelIdsList,
		channelSubscription: data
	};
	console.log('saveMultiChannelSubs action ', multiChannelSubsObj);

	const request = axios.post(
		'EpttaAdmin/ChannelSubscription/multiChannelSubscriptionImportation',
		multiChannelSubsObj
	);

	return dispatch =>
		request
			.then(response => {
				dispatch(showMessage({ variant: 'success', message: message }));
				return dispatch({
					type: SAVE_MULTI_CHANNEL_SUBS,
					payload: response.data,
					channelIdsList: channelIdsList
				});
			})
			.catch(err => {
				if (err?.response && err.response?.data) {
					if (err.response.data.error) {
						dispatch(showMessage({ variant: 'error', message: err.response.data.error }));
					} else {
						dispatch(showMessage({ variant: 'error', message: err.response.data }));
					}
				}
			});
}

export function newChannelSubTemplate(tenant, sourceAgentId, targetChannelId) {
	const data = {
		_id: 'new',
		agentId: sourceAgentId,
		channelId: targetChannelId,
		channelSubscriptionPermissions: {
			allowJoin: true,
			allowListen: true,
			allowMute: true,
			allowReceiveAttachment: true,
			allowReceiveTextMessage: true,
			allowSendAttachment: true,
			allowSendTextMessage: true,
			allowSpeak: true,
			created: '2020-03-22T02:38:12.475Z',
			lastUpdate: '2020-03-22T02:38:12.475Z',
			priority: 0
		},
		channelSubscriptionState: {
			_id: 'new',
			created: '2020-03-22T02:38:12.475Z',
			deviceId: 'string',
			id: 'string',
			isAlwaysListening: true,
			isMuted: true,
			lastUpdate: '2020-03-22T02:38:12.475Z',
			sessionId: 'string'
		},
		created: '2020-03-22T02:38:12.475Z',
		id: 'new',
		lastUpdate: '2020-03-22T02:38:12.475Z',
		permissions: {
			allowJoin: true,
			allowListen: true,
			allowMute: true,
			allowReceiveAttachment: true,
			allowReceiveTextMessage: true,
			allowSendAttachment: true,
			allowSendTextMessage: true,
			allowSpeak: true,
			created: '2020-03-22T02:38:12.475Z',
			lastUpdate: '2020-03-22T02:38:12.475Z',
			priority: 0
		},
		state: {
			_id: 'new',
			created: '2020-03-22T02:38:12.475Z',
			deviceId: 'string',
			id: 'string',
			isAlwaysListening: false,
			isMuted: false,
			lastUpdate: '2020-03-22T02:38:12.475Z',
			sessionId: 'string'
		}
	};

	return {
		type: GET_CHANNELSUB,
		payload: data
	};
}

export function setSelectedCTargetChannel(channelId) {
	return {
		type: SET_SELECTED_CTARGET_CHANNEL,
		selectedTargetChannel: channelId
	};
}

export function setSelectedCSourceAgent(agentId) {
	return {
		type: SET_SELECTED_CSOURCE_AGENT,
		selectedSourceAgent: agentId
	};
}

export function isMultiChannelsSubs(state) {
	return {
		type: IS_MULTI_CHANNELS_SUBS,
		payload: state
	};
}
